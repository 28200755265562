import * as React from 'react'
import { graphql } from "gatsby"
import Layout from '../components/layout'
import PostLink from "../components/post-link"

const TexterPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {    
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)
  return(
    <Layout pageTitle="Viktiga sånger">
      <div className='max-w-lg'>{Posts}</div> 
    </Layout>
  )

}

export default TexterPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
          }
        }
      }
    }
  }
`