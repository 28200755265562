import React from "react"
import { Link } from "gatsby"

const PostLink = ({ post }) => (
  <div className="">
    <Link className="block p-2 mb-4 shadow-md rounded-md hover:bg-gray-100" to={post.frontmatter.slug}>
      {post.frontmatter.title}
    </Link>
  </div>
)

export default PostLink